<template>
  <footer class="base-footer">
    <div class="footer-wrapper">
      <div class="footer-wrapper--table">
        <nav class="footer-links">
          <span class="footer-links-item">
            <h5 class="font--b1 text-white-monochrome">
              {{ $t("footer.f_comm") }}
            </h5>
            <div class="flexbox">
              <!-- <a href=""
              ><base-icon
                name="base/DiscordLogo"
                size="32px"
                color="var(--gray-monochrome)"
            /></a> -->
              <a
                href="https://www.linkedin.com/company/rwa-scan/"
                target="_blank"
                ><base-icon
                  name="base/LinkedinLogo"
                  size="32px"
                  color="var(--gray-monochrome)"
              /></a>
              <!-- <a href=""
              ><base-icon
                name="base/Twitter"
                size="32px"
                color="var(--gray-monochrome)"
            /></a> -->

              <a href="https://t.me/rwasupport" target="_blank">
                <base-icon
                  name="base/telegram"
                  size="32px"
                  color="var(--gray-monochrome)"
                />
              </a>
            </div>
          </span>
          <span class="footer-links-item">
            <h5 class="font--b1 text-white-monochrome">
              {{ $t("footer.f_about") }}
            </h5>
            <!--            <a class="font&#45;&#45;b2" href="">About Us</a>-->
            <a class="font--b2" href="/faq" style="padding-top: 4px">FAQs</a>
          </span>
          <div class="footer-wrapper--info">
            <a href="mailto:hi@rwa-estate.com" class="font--b2 mobile-font--b5"
              >hi@rwa-estate.com</a
            >
          </div>
          <!-- <span class="footer-links-item"> -->
          <!--            <h5 class="font&#45;&#45;b1 text-white-monochrome">Suport</h5>-->
          <!--            <a class="font&#45;&#45;b2" href="">Privacy Policy</a>-->
          <!--            <a class="font&#45;&#45;b2" href="">Terms & Conditions</a>-->
          <!-- </span> -->
        </nav>
        <section class="footer-subscription">
          <h5
            class="font--b1 text-white-monochrome mobile-font--b2"
            style="margin-bottom: 4px"
          >
            {{ $t("footer.f_updated") }}
          </h5>
          <p class="font--b2 text-gray-monochrome mobile-font--b3">
            {{ $t("footer.f_launch") }}
          </p>
          <base-subscribe
            :text="$t('footer.f_submit')"
            :anim-text="$t('footer.f_submit-done')"
          />
        </section>
      </div>

      <div class="footer-wrapper--logo">
        <img src="~/assets/icons/Logo_light.svg" alt="" >
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.base-footer {
  height: 485px;
  width: 100dvw;
  overflow: hidden;
  background-color: var(--black-monochrome);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  .footer-wrapper {
    position: relative;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 40px 180px 0px 180px;
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    gap: 60px;

    @media (max-width: 2599px) {
      max-width: 1440px;
      margin: auto;
    }
    @media (min-width: 2600px) {
      max-width: 2600px;
      margin: auto;
    }
    &--table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      row-gap: 32px;
      .footer {
        &-links {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          height: 100%;

          &-item {
            display: grid;
            grid-template-columns: 1fr;
            align-self: start;
            row-gap: 20px;
            padding-left: 10px;
            .flexbox {
              max-width: 150px;
              display: flex;
              flex-wrap: wrap;
              gap: 24px;
            }
            h5 {
              margin-bottom: 4px;
            }

            a {
              text-decoration: none;
              color: var(--gray-monochrome);
            }
          }
        }
        &-subscription {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          justify-items: flex-start;
          justify-self: flex-end;
          align-self: flex-start;
          max-width: 422px;
          gap: 16px;
        }
      }
    }
    &--info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-column: span 3;
      padding-left: 10px;
      align-self: flex-end;
      a {
        color: var(--gray-monochrome);
        text-decoration: none;
      }
    }
    &--logo {
      position: relative;
      align-self: flex-end;
      width: 100%;
      height: 160px;
      margin-top: 4px;
      img {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        max-height: 160px;
      }
    }
  }
}

@media (max-width: 42rem) {
  .base-footer {
    height: auto;
    width: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .footer-wrapper {
      padding: 40px 0;
      gap: 32px;

      &--table {
        grid-template-columns: 1fr;
        padding: 0 24px;

        .footer {
          &-links {
            grid-template-columns: 1fr;
            row-gap: 40px;
            column-gap: 40px;
            width: fit-content;

            &-item {
              row-gap: 24px;

              .flexbox {
                max-width: unset;
              }
            }
          }

          &-subscription {
            border-radius: 16px;
            border: 1px solid var(--white-transparent);
            max-width: 345px;
            padding: 16px;
            gap: 2px;
            justify-self: unset;

            p {
              padding-bottom: 23px;
            }
          }
        }
      }

      &--logo {
        height: auto;
        grid-row-end: 1;
        padding: 0 24px 30px;
        margin: 0;
        border-bottom: 1px solid var(--gray-monochrome-20);

        img {
          position: relative;
          bottom: unset;
          left: unset;
          width: 203px;
          max-height: unset;
        }
      }
    }
  }
  @include mobile-fonts;
}
</style>
