import revive_payload_client_GDspCtXrKu from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Jj6YNBBGuR from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yAVUVgMegl from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6e2v8nV4Ir from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79._6g6r4pbrfvkz2zra23znugwj3a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import gmMaps_KV68qKtSx0 from "/vercel/path0/plugins/gmMaps.ts";
import payload_client_99IztczwBe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WgYRMCldlR from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8CFtOd87yd from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TTF0kyRXhC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CYndHNdpNL from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_bt1p1kqSHb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5THmCYSCuw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SsXXOFiF2K from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_6bpRtJJmZ1 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1._qqijfqu27atjvqhutpuivm5yli/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_j0gHuJJEgV from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1._qqijfqu27atjvqhutpuivm5yli/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_D0QVFNGKeB from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.6_@quasar+extras@1.16.12_magicast@0.3.5_quasar@2.17.0_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import gtm_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
import rest_api_zNWx1STPxo from "/vercel/path0/plugins/rest-api.js";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import image_error_Tix9NvX1QD from "/vercel/path0/plugins/image-error.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/plugins/hotjar.client.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import init_wReGGcsrlG from "/vercel/path0/node_modules/.pnpm/nuxt-schema-org@3.4.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.5__t5zhmqfkd4cuukrxli2qacxona/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  revive_payload_client_GDspCtXrKu,
  unhead_Jj6YNBBGuR,
  router_yAVUVgMegl,
  _0_siteConfig_6e2v8nV4Ir,
  gmMaps_KV68qKtSx0,
  payload_client_99IztczwBe,
  navigation_repaint_client_WgYRMCldlR,
  check_outdated_build_client_8CFtOd87yd,
  chunk_reload_client_TTF0kyRXhC,
  plugin_vue3_CYndHNdpNL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bt1p1kqSHb,
  switch_locale_path_ssr_5THmCYSCuw,
  i18n_SsXXOFiF2K,
  siteConfig_6bpRtJJmZ1,
  inferSeoMetaPlugin_j0gHuJJEgV,
  plugin_wy0B721ODc,
  plugin_D0QVFNGKeB,
  gtm_MVzSxF8h1r,
  rest_api_zNWx1STPxo,
  error_handler_kEP5FliEXj,
  image_error_Tix9NvX1QD,
  hotjar_client_HJG8no80GI,
  sentry_3AyO8nEfhE,
  init_wReGGcsrlG
]