import noIcon from "~/assets/icons/no-icon.svg";

let timer: ReturnType<typeof setTimeout>;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("image-error", {
    mounted(
      el: HTMLImageElement,
      binding: {
        value: {
          timeout?: number;
          onImageError?: Function;
          onImageLoad?: Function;
        };
      }
    ) {
      if (el.src.includes("no-icon")) return; // Пропуск, если уже fallback

      const timeout = binding.value?.timeout || 5000;
      const onImageError = binding.value?.onImageError;
      const onImageLoad = binding.value?.onImageLoad;
      let loaded = false; // Флаг для предотвращения ненужной замены

      // Проверяем, если изображение уже загружено (для кэшированных изображений)
      if (el.complete && el.naturalWidth !== 0) {
        handleLoad(); // Если уже загружено, вызовем onload вручную
      } else {
        // Устанавливаем обработчики для обычной загрузки
        el.onload = handleLoad;
        el.onerror = handleError;

        // Таймер на случай задержки загрузки
        timer = setTimeout(() => {
          if (!loaded && el.src !== noIcon) {
            console.warn(`Image load timeout: ${el.src}`);
            replaceWithFallback();
            if (onImageError) onImageError();
          }
        }, timeout);
      }

      // Обработчик успешной загрузки
      function handleLoad() {
        if (loaded || el.src.includes("no-icon")) return; // Предотвращаем повторные вызовы
        loaded = true;
        console.log("Image loaded successfully:", el.src);
        clearTimeout(timer); // Останавливаем таймер
        if (onImageLoad) onImageLoad(el.src);
        cleanup(); // Убираем обработчики
      }

      // Обработчик ошибки загрузки
      function handleError() {
        if (el.src.includes("no-icon")) return; // Предотвращаем бесконечный цикл
        console.warn(`Image load error: ${el.src}`);
        clearTimeout(timer); // Останавливаем таймер
        replaceWithFallback();
        if (onImageError) onImageError();
        cleanup(); // Убираем обработчики
      }

      // Замена на fallback-иконку
      function replaceWithFallback() {
        if (!loaded) {
          el.src = noIcon;
        }
      }

      // Убираем обработчики
      function cleanup() {
        el.onload = null;
        el.onerror = null;
      }
    },

    unmounted(el: HTMLImageElement) {
      // Очищаем таймер и обработчики при размонтировании
      el.onload = null;
      el.onerror = null;
      clearTimeout(timer);
    },
  });
});
