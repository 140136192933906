import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFAQPrimary, LazyMainFAQ, LazyMainFind, LazyMainHero, LazyMainHot, LazyMainInvestment, LazyMainNewOffers, LazyMainPartners, LazyMainTrending, LazyAdminSearchCard, LazyBaseButton, LazyBaseCard, LazyBaseComingSoon, LazyBaseCookiePopUp, LazyBaseCountryPhoto, LazyBaseCustomSelect, LazyBaseErrorBackdrop, LazyBaseFaq, LazyBaseFieldBlocker, LazyBaseFilesUploaderImgPreview, LazyBaseFilesUploader, LazyBaseFooter, LazyBaseGallery, LazyBaseGetInTouch, LazyBaseHeader, LazyBaseIcon, LazyBaseInputButton, LazyBaseLanguageButton, LazyBaseLoader, LazyBaseLogoLoader, LazyBaseModalСonfirmation, LazyBaseNavigatorFiltersCheckbox, LazyBaseNavigatorFiltersInputRange, LazyBaseNavigatorFiltersMultyButts, LazyBaseNavigator, LazyBaseNavigatorNavItem, LazyBasePageLoader, LazyBasePageTabs, LazyBasePaginator, LazyBasePriceRangeSlider, LazyBaseProgressBar, LazyBasePropertyInfo, LazyBaseSearchCard, LazyBaseSearchCardNew, LazyBaseSearchCardSoldOutTag, LazyBaseSearchCardVisitors, LazyBaseSearch, LazyBaseSeparator, LazyBaseSubscribe, LazyBaseSuggestedEstates, LazyBaseTag, LazyBaseTooltip, LazyBaseUnitTimeline, LazyCalculator, LazyComparisonMapBlock, LazyItemEditor, LazyLoginLoginPopUp, LazyLoginRestorePopUp, LazyMapStatic, LazyMobileSwiperGallery, LazyPhotoWall, LazyPhotoWallToDELETE, LazyProfileMobileUserSettingsPopUp, LazyProfileUserSettingsPopUp, LazySearchMobileFilter, LazySearchMobileFilterPopup, LazySearchPageNoObjects, LazySwiperGallery, LazySwiperSlider, LazyUnitPageCompareBlock, LazyUnitPageFinanceBlock, LazyUnitPageInfoBlock, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["FAQPrimary", LazyFAQPrimary],
["MainFAQ", LazyMainFAQ],
["MainFind", LazyMainFind],
["MainHero", LazyMainHero],
["MainHot", LazyMainHot],
["MainInvestment", LazyMainInvestment],
["MainNewOffers", LazyMainNewOffers],
["MainPartners", LazyMainPartners],
["MainTrending", LazyMainTrending],
["AdminSearchCard", LazyAdminSearchCard],
["BaseButton", LazyBaseButton],
["BaseCard", LazyBaseCard],
["BaseComingSoon", LazyBaseComingSoon],
["BaseCookiePopUp", LazyBaseCookiePopUp],
["BaseCountryPhoto", LazyBaseCountryPhoto],
["BaseCustomSelect", LazyBaseCustomSelect],
["BaseErrorBackdrop", LazyBaseErrorBackdrop],
["BaseFaq", LazyBaseFaq],
["BaseFieldBlocker", LazyBaseFieldBlocker],
["BaseFilesUploaderImgPreview", LazyBaseFilesUploaderImgPreview],
["BaseFilesUploader", LazyBaseFilesUploader],
["BaseFooter", LazyBaseFooter],
["BaseGallery", LazyBaseGallery],
["BaseGetInTouch", LazyBaseGetInTouch],
["BaseHeader", LazyBaseHeader],
["BaseIcon", LazyBaseIcon],
["BaseInputButton", LazyBaseInputButton],
["BaseLanguageButton", LazyBaseLanguageButton],
["BaseLoader", LazyBaseLoader],
["BaseLogoLoader", LazyBaseLogoLoader],
["BaseModalСonfirmation", LazyBaseModalСonfirmation],
["BaseNavigatorFiltersCheckbox", LazyBaseNavigatorFiltersCheckbox],
["BaseNavigatorFiltersInputRange", LazyBaseNavigatorFiltersInputRange],
["BaseNavigatorFiltersMultyButts", LazyBaseNavigatorFiltersMultyButts],
["BaseNavigator", LazyBaseNavigator],
["BaseNavigatorNavItem", LazyBaseNavigatorNavItem],
["BasePageLoader", LazyBasePageLoader],
["BasePageTabs", LazyBasePageTabs],
["BasePaginator", LazyBasePaginator],
["BasePriceRangeSlider", LazyBasePriceRangeSlider],
["BaseProgressBar", LazyBaseProgressBar],
["BasePropertyInfo", LazyBasePropertyInfo],
["BaseSearchCard", LazyBaseSearchCard],
["BaseSearchCardNew", LazyBaseSearchCardNew],
["BaseSearchCardSoldOutTag", LazyBaseSearchCardSoldOutTag],
["BaseSearchCardVisitors", LazyBaseSearchCardVisitors],
["BaseSearch", LazyBaseSearch],
["BaseSeparator", LazyBaseSeparator],
["BaseSubscribe", LazyBaseSubscribe],
["BaseSuggestedEstates", LazyBaseSuggestedEstates],
["BaseTag", LazyBaseTag],
["BaseTooltip", LazyBaseTooltip],
["BaseUnitTimeline", LazyBaseUnitTimeline],
["Calculator", LazyCalculator],
["ComparisonMapBlock", LazyComparisonMapBlock],
["ItemEditor", LazyItemEditor],
["LoginLoginPopUp", LazyLoginLoginPopUp],
["LoginRestorePopUp", LazyLoginRestorePopUp],
["MapStatic", LazyMapStatic],
["MobileSwiperGallery", LazyMobileSwiperGallery],
["PhotoWall", LazyPhotoWall],
["PhotoWallToDELETE", LazyPhotoWallToDELETE],
["ProfileMobileUserSettingsPopUp", LazyProfileMobileUserSettingsPopUp],
["ProfileUserSettingsPopUp", LazyProfileUserSettingsPopUp],
["SearchMobileFilter", LazySearchMobileFilter],
["SearchMobileFilterPopup", LazySearchMobileFilterPopup],
["SearchPageNoObjects", LazySearchPageNoObjects],
["SwiperGallery", LazySwiperGallery],
["SwiperSlider", LazySwiperSlider],
["UnitPageCompareBlock", LazyUnitPageCompareBlock],
["UnitPageFinanceBlock", LazyUnitPageFinanceBlock],
["UnitPageInfoBlock", LazyUnitPageInfoBlock],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
