/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface JwtResponseDTO {
  /** Access token */
  accessToken: string
  /** Refresh token */
  refreshToken: string
}

export interface SignUpDTO {
  /** Email address */
  email: string
  /** Password */
  password: string
}

export interface SignUpConfirmDTO {
  /** Email address */
  email: string
  /** Token from letter */
  token: string
}

export interface SignUpAppendDTO {
  /** Email address */
  email: string
  /** New password */
  password?: string
}

export interface SignUpAppendConfirmDTO {
  /** Email address */
  email: string
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface SignUpResendDTO {
  /** Email address */
  email: string
}

export interface SignInDTO {
  /** Login */
  login: string
  /**  Password */
  password: string
}

export interface PasswordRecoveryDTO {
  /** Email address */
  email: string
  /** New password */
  password?: string
}

export interface PasswordRecoveryConfirmDTO {
  /** Email address */
  email: string
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface PasswordChangeDTO {
  /** New password */
  password?: string
  /** Old password */
  oldPassword?: string
}

export interface PasswordChangeConfirmDTO {
  /** New password */
  password: string
  /** Token from email */
  token: string
}

export interface UserTOTPDTO {
  /** Secret */
  secret: string
  /** qr-code */
  qr: string
}

export interface UserLoginAttemptDTO {
  /** Counter */
  counter: number
  /** Locked till */
  lockedTill?: number
}

export interface UserSettingsDTO {
  /** User old status */
  oldStatus?: 'new' | 'active' | 'draft' | 'banned' | 'deleted' | 'temporary' | 'service'
  /** Subscribe to news */
  subscriptionNews?: boolean
  /** Language */
  language?: string
  /** Timezone */
  timezone?: string
  /** Register finished */
  isRegistered?: boolean
  /** 2FA saved by user */
  is2FASaved?: boolean
  /** 2FA settings */
  totp?: UserTOTPDTO
  /** Login attempt */
  loginAttempt?: UserLoginAttemptDTO
}

export interface FileDTO {
  /** Unique identifier */
  id: string
  /** Name of file */
  name: string
  /** Extension of file */
  ext: string
  /** Url of file */
  url: string
  /** Url of file */
  originUrl: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface PropertyDocumentDTO {
  /** Title */
  name: string
  /** URL */
  url: string
}

export interface PropertyPartnerDTO {
  /** Partner name */
  name: string
  /** Partner subtitle */
  subtitle: string
  /** logo */
  logo: string
  /** Web site */
  url?: string
}

export interface PropertyFileDTO {
  /** Unique identifier */
  id: string
  /** Unique identifier */
  estateId: string
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
  /** File */
  file: FileDTO
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface CountryDTO {
  /** @example "China" */
  name: string
  /** @example "CN" */
  code: string
}

export interface ChainDTO {
  /** Chain id by chain-list */
  id: number
  /** Name of chain */
  name: string
  /** Chain type */
  type: 'evm'
  /** Scan url */
  url: string
}

export interface TokenIssuerDTO {
  /** Issuer name */
  name: string
  /** Issuer description */
  description: string
  /** Issuer url */
  url: string
  /** Issuer logo url */
  logo: string
  /** Issuer industry */
  industry: string
  /** Issuer founding year */
  foundingYear: number
}

export interface TokenHolderDTO {
  /** Unique identifier */
  id: string
  /** Token id */
  tokenId: string
  /** Wallet address */
  address: string
  /** wallet token balance */
  balance: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface TokenDTO {
  /** Unique identifier */
  id: string
  /** Token name */
  name?: string
  /** Token symbol */
  symbol?: string
  /** Token decimals */
  decimals?: number
  /** Token total supply */
  totalSupply?: string
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Token available for purchase */
  availableSupplyPercentage?: number
  /** Token chain id */
  chainId?: number
  /** Chain */
  chain?: ChainDTO
  /** Chain name */
  address?: string
  /** Icon */
  icon?: string
  /** URL scan */
  url?: string
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: string
  /** Issuer */
  issuer?: TokenIssuerDTO
  /** Jurisdiction */
  jurisdiction?: string
  /** Description */
  description?: string
  /** Wallet balances */
  holders: TokenHolderDTO[]
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface EstateFinanceProjectTotalIncomeSubfieldsDTO {
  /** Projected rental income */
  projectedRentalIncome: number
  /** Projection Appreciation */
  projectionAppreciation: number
}

export interface FinanceGroupDTO {
  /** Summary by group */
  value: number
  /** Subfields */
  subfields: EstateFinanceProjectTotalIncomeSubfieldsDTO
}

export interface EstateFinanceDTO {
  /** Project total income */
  projectTotalIncome: FinanceGroupDTO
  /** Total investment value */
  totalInvestmentValue: FinanceGroupDTO
  /** Annual Cash Flow */
  annualCashFlow: number
  /** Month Cash Flow */
  monthCashFlow: number
  /** Annual Gross Income */
  annualGrossIncome: number
  /** Maintaining costs */
  maintainingCosts: FinanceGroupDTO
}

export interface EstateTimelineDTO {
  /** Unique identifier */
  id: string
  /** Unique identifier of estate */
  estateId: string
  /** Stage */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /**
   * Stage date
   * @example "2024-01-01"
   */
  date: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface ProviderReferralLinkDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider name */
  providerName: string
  /** Provider link */
  link: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface EstateDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId: string
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: PropertyDocumentDTO[]
  /** Platform partners */
  partners: PropertyPartnerDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Files */
  files: PropertyFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenDTO
  /** Metrics */
  metrics: object
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /** Estate tags */
  tags: ('new' | 'trending')[]
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'stage'
    | 'description'
    | 'irr'
    | 'apr'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'bathroom'
    | 'bedroom'
    | 'partners'
    | 'tags'
  )[]
  /** Estate timeline */
  timeline: EstateTimelineDTO[]
  /** Referral link */
  referralLink: ProviderReferralLinkDTO
  /** translations */
  translations?: object
}

export interface UserDTO {
  /** Unique identifier */
  id: string
  /** User email address */
  email?: string
  /** User name */
  username?: string
  /** First name */
  firstName?: string
  /** Last name */
  lastName?: string
  /** User role */
  role: 'default' | 'guest' | 'admin' | 'service'
  /** User status */
  status: 'new' | 'active' | 'draft' | 'banned' | 'deleted' | 'temporary' | 'service'
  /** User settings */
  settings: UserSettingsDTO
  /** Avatar id */
  avatar?: string
  /** Avatar file */
  avatarFile?: FileDTO
  /** Favorite properties */
  favoriteEstates: EstateDTO
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface UpdateUserSettingsDTO {
  /** Subscribe to news */
  subscriptionNews?: boolean
}

export interface UpdateUserDTO {
  /** User email address */
  email?: string
  /** User name */
  username?: string
  /** First name */
  firstName?: string
  /** Last name */
  lastName?: string
  /** Avatar id */
  avatar?: string
  /** Update user settings */
  settings?: UpdateUserSettingsDTO
}

export interface UnauthorizedExceptionDTO {
  /** @example 401 */
  statusCode: number
  /** @example "Unauthorized" */
  message: string
  /** @example "Unauthorized" */
  error: string
}

export interface ProviderDTO {
  /** Provider name */
  name: string
  /** Provider url */
  url: string
  /** Provider logo */
  logo: string
  /** Provider referral links */
  providerReferralLinks: ProviderReferralLinkDTO[]
}

export interface CreateProviderReferralLinkDTO {
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider link */
  link: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface BadRequestExceptionDTO {
  /** @example 400 */
  statusCode: number
  /** @example "Bad request" */
  message: string
  /** @example "Bad request" */
  error: string
}

export interface NotFoundExceptionDTO {
  /** @example 404 */
  statusCode: number
  /** @example "Not Found" */
  message: string
  /** @example "Not Found" */
  error: string
}

export interface UpdateProviderReferralLinkDTO {
  /**
   * Expired at
   * @format date-time
   */
  expiredAt?: string
  /** Provider name */
  providerName?: string
  /** Provider link */
  link?: string
  /** Provider description */
  description?: string
  /** Default link for provider */
  default?: boolean
}

export interface ImportFileItemDTO {
  /** Name of file */
  name: string
  /** Url of file */
  url: string
}

export interface ImportFileDTO {
  files: ImportFileItemDTO[]
}

export interface PaginationResultDto {
  /** Number of results */
  count: number
  /** Array of results */
  rows: LandResponseDTO[]
}

export interface CreateTokenPayloadDTO {
  /** Token name */
  name?: string
  /** Token symbol */
  symbol?: string
  /** Token decimals */
  decimals?: number
  /** Token total supply */
  totalSupply?: string
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Token chain id */
  chainId?: number
  /** Chain name */
  address?: string
  /** Icon */
  icon?: string
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Issuer */
  issuer?: TokenIssuerDTO
  /** Jurisdiction */
  jurisdiction?: string
  /** Description */
  description?: string
}

export interface UpdateTokenPayloadDTO {
  /** Token circulating */
  circulatingSupply?: string
  /** Token available for purchase */
  availableSupply?: string
  /** Icon */
  icon?: string
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Issuer */
  issuer?: TokenIssuerDTO
  /** Jurisdiction */
  jurisdiction?: string
  /** Description */
  description?: string
}

export interface NumberFilterDTO {
  /** Greater than equal */
  gte?: number
  /** Less than equal */
  lte?: number
  /** Equal */
  equals?: number
}

export interface EstateFilterDTO {
  /**
   * limit value
   * @min 1
   * @default 10
   */
  limit?: number
  /**
   * offset value
   * @min 0
   * @default 0
   */
  offset?: number
  /** search value */
  search?: string
  /** order value */
  order?: {
    roi?: 'asc' | 'desc'
    pi?: 'asc' | 'desc'
    priceUSD?: 'asc' | 'desc'
    'token.priceUSD'?: 'asc' | 'desc'
    apr?: 'asc' | 'desc'
    irr?: 'asc' | 'desc'
  }
  /**
   * filter from date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  from?: string
  /**
   * filter to date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  to?: string
  /** Unique ids for filter */
  ids?: string[]
  /** Filter by status */
  status?: ('new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted')[]
  /** Filter by country */
  countryId?: string[]
  /** Filter by bathroom */
  bathroom?: number[]
  /** Filter by bedroom */
  bedroom?: number[]
  /** Filter by stage */
  stage?: (
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  )[]
  /** Filter by stage */
  tag?: (
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  )[]
  /** Filter by price */
  price?: NumberFilterDTO
  /** Filter by price */
  tokenPrice?: NumberFilterDTO
  /** Filter by apr */
  apr?: NumberFilterDTO
  /** Filter by irr */
  irr?: NumberFilterDTO
  /** Filter by minimum investment */
  minimumInvestment?: NumberFilterDTO
}

export interface EstateSimilarDTO {
  /** Unique identifier */
  id: string
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Platform partners */
  partners: PropertyPartnerDTO[]
  /** Files */
  files: PropertyFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenDTO
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Estate tags */
  tags: ('new' | 'trending')[]
  /** Is favorite */
  favorite: boolean
}

export interface EstateResponseDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId: string
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: PropertyDocumentDTO[]
  /** Platform partners */
  partners: PropertyPartnerDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Files */
  files: PropertyFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenDTO
  /** Metrics */
  metrics: object
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating: number
  /** Return On Investment */
  roi?: number
  /** Profitability index */
  pi?: number
  /** Priority output */
  priority: number
  /** Estate tags */
  tags: ('new' | 'trending')[]
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'stage'
    | 'description'
    | 'irr'
    | 'apr'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'bathroom'
    | 'bedroom'
    | 'partners'
    | 'tags'
  )[]
  /** Estate timeline */
  timeline: EstateTimelineDTO[]
  /** Referral link */
  referralLink: ProviderReferralLinkDTO
  /** translations */
  translations?: object
  /** Similar estates */
  similar: EstateSimilarDTO[]
  /** Is favorite */
  favorite: boolean
}

export interface EstateFilePayloadDTO {
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface CreateEstatePayloadDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /**
   * Stage
   * @default "complete"
   */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'stage'
    | 'description'
    | 'irr'
    | 'apr'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'bathroom'
    | 'bedroom'
    | 'partners'
    | 'tags'
  )[]
  /** Referral link */
  referralLink: ProviderReferralLinkDTO
  /** translations */
  translations?: object
  /** Token id */
  tokenId?: string
  /** Documents */
  documents?: PropertyDocumentDTO[]
  /** Platform partners */
  partners?: PropertyPartnerDTO[]
  /** Rating */
  rating?: number
  /** Estate tags */
  tags?: ('new' | 'trending')[]
  /** Token payload */
  token?: CreateTokenPayloadDTO
  files?: EstateFilePayloadDTO[]
}

export interface UpdateEstatePriorityDTO {
  /** Array of estate ids */
  estateIds: string[]
}

export interface EstateStatisticGraphRangeDTO {
  /** Field name of range */
  range: number
  /** Count of estates */
  count: number
}

export interface EstateStatisticFilterResponseDTO {
  /** Total count */
  total: number
  /** Current maximum price */
  priceMax: number
  /** Current minimum price */
  priceMin: number
  /**
   * Group ranges
   * @deprecated
   */
  priceRange: EstateStatisticGraphRangeDTO[]
  /** Prices */
  prices: EstateStatisticGraphRangeDTO
  /** Current maximum irr */
  irrMax: number
  /** Current minimum irr */
  irrMin: number
  /**
   * Group ranges
   * @deprecated
   */
  irrRange: EstateStatisticGraphRangeDTO[]
  /** Current maximum apr */
  aprMax: number
  /** Current minimum apr */
  aprMin: number
  /**
   * Group ranges
   * @deprecated
   */
  aprRange: EstateStatisticGraphRangeDTO[]
  /** Current maximum token price */
  tokenPriceMax: number
  /** Current minimum token price */
  tokenPriceMin: number
  /** Group ranges */
  tokenPriceRange: EstateStatisticGraphRangeDTO[]
  /** Token prices */
  tokenPrices: EstateStatisticGraphRangeDTO
  /** Country count */
  country: object
  /** Bathroom count */
  bathroom: object
  /** Bedroom count */
  bedroom: object
  /** Stage count */
  stage: object
  /** Tag count */
  tags: object
  /** Maximum investment */
  maxInvestment: number
}

export interface EstateFileDTO {
  /** Unique identifier */
  id: string
  /** Unique identifier */
  estateId: string
  /** Unique identifier */
  fileId: string
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
  /** File */
  file: FileDTO
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export interface UpdateEstateReferralLinkDTO {
  /** Array of estate ids */
  estateIds: string[]
  /** Referral link unique identifier */
  referralLinkId: string
}

export interface UpdateEstatePayloadDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: PropertyDocumentDTO[]
  /** Platform partners */
  partners?: PropertyPartnerDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /**
   * Stage
   * @default "complete"
   */
  stage?:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /** Internal Rate of Return */
  irr?: number
  /** Annual Percentage Rate */
  apr?: number
  /** Finance data */
  finance?: EstateFinanceDTO
  /** Bathroom count */
  bathroom?: number
  /** Bedroom count */
  bedroom?: number
  /** Rating */
  rating?: number
  /** Estate tags */
  tags?: ('new' | 'trending')[]
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'stage'
    | 'description'
    | 'irr'
    | 'apr'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'bathroom'
    | 'bedroom'
    | 'partners'
    | 'tags'
  )[]
  /** Referral link */
  referralLink?: ProviderReferralLinkDTO
  /** translations */
  translations?: object
  files?: EstateFilePayloadDTO[]
  /** Update token info */
  token?: UpdateTokenPayloadDTO
}

export interface UpdateEstateFilePayloadDTO {
  /** Unique identifier */
  type: 'image' | 'document' | 'covers'
}

export interface CreateEstateTimelineDTO {
  /** Stage */
  stage:
    | 'in_progress'
    | 'complete'
    | 'ConstructionLaunched'
    | 'ConstructionFinished'
    | 'ListingDate'
    | 'PropertyAvaliableForRental'
    | 'StartOfFirstPayments'
  /**
   * Stage date
   * @example "2024-01-01"
   */
  date: string
}

export interface LandFilterDTO {
  /**
   * limit value
   * @min 1
   * @default 10
   */
  limit?: number
  /**
   * offset value
   * @min 0
   * @default 0
   */
  offset?: number
  /** search value */
  search?: string
  /** order value */
  order?: {
    priceUSD?: 'asc' | 'desc'
    'token.priceUSD'?: 'asc' | 'desc'
  }
  /**
   * filter from date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  from?: string
  /**
   * filter to date
   * @format date-time
   * @example "2020-06-15T10:30:50.000Z"
   */
  to?: string
  /** Unique ids for filter */
  ids?: string[]
  /** Filter by country */
  countryId?: string[]
  /** Filter by price */
  price?: NumberFilterDTO
  /** Filter by price */
  'token.priceUSD'?: NumberFilterDTO
  /** Filter by minimum investment */
  minimumInvestment?: NumberFilterDTO
}

export interface LandFinanceDTO {
  /** Project total income */
  projectTotalIncome: FinanceGroupDTO
  /** Total investment value */
  totalInvestmentValue: FinanceGroupDTO
  /** Annual Cash Flow */
  annualCashFlow: number
  /** Month Cash Flow */
  monthCashFlow: number
  /** Annual Gross Income */
  annualGrossIncome: number
  /** Maintaining costs */
  maintainingCosts: FinanceGroupDTO
}

export interface LandSimilarDTO {
  /** Unique identifier */
  id: string
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Platform partners */
  partners: PropertyPartnerDTO[]
  /** Files */
  files: PropertyFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenDTO
  /** Is favorite */
  favorite: boolean
}

export interface LandResponseDTO {
  /** Unique identifier */
  id: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
  /** Provider id */
  providerId: string
  /** Token id */
  tokenId: string
  /** Status */
  status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted'
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Minimum investment value */
  minimumInvestment?: number
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Price USD */
  priceUSD?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents: PropertyDocumentDTO[]
  /** Platform partners */
  partners: PropertyPartnerDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Referral link ID */
  referralLinkId?: string
  /** Files */
  files: PropertyFileDTO[]
  /** Country */
  country?: CountryDTO
  /** Token */
  token: TokenDTO
  /** Metrics */
  metrics: object
  /** Land finance */
  finance?: LandFinanceDTO
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'description'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'partners'
  )[]
  /** Similar estates */
  similar: LandSimilarDTO[]
  /** Is favorite */
  favorite: boolean
}

export interface LandFilePayloadDTO {
  /** Unique identifier */
  fileId: string
  /** File type */
  type: 'image' | 'document' | 'covers'
}

export interface CreateLandDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Land finance */
  finance?: LandFinanceDTO
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'description'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'partners'
  )[]
  /** Token id */
  tokenId?: string
  /** Documents */
  documents?: PropertyDocumentDTO[]
  /** Platform partners */
  partners?: PropertyPartnerDTO[]
  /** Token payload */
  token?: CreateTokenPayloadDTO
  /** Land files */
  files?: LandFilePayloadDTO[]
}

export interface UpdateLandDTO {
  /** Country id */
  countryId?: string
  /** Location */
  location?: string
  /** Coordinates */
  coords?: number[]
  /** Currency */
  currency?: string
  /** Price */
  price?: number
  /** Estate name */
  name?: string
  /** Description */
  description?: string
  /** Apartment area */
  area?: number
  /** Documents */
  documents?: PropertyDocumentDTO[]
  /** Platform partners */
  partners?: PropertyPartnerDTO[]
  /** Ownership status */
  ownershipStatus?: 'Leasehold' | 'Freehold'
  /** Ownership text */
  ownershipText?: string
  /** Land finance */
  finance?: LandFinanceDTO
  /** Blocked field */
  blockedField?: (
    | 'name'
    | 'countryId'
    | 'coords'
    | 'location'
    | 'description'
    | 'finance'
    | 'currency'
    | 'price'
    | 'priceUSD'
    | 'area'
    | 'partners'
  )[]
  /** Token payload */
  token?: UpdateTokenPayloadDTO
  /** Land files */
  files?: LandFilePayloadDTO[]
}

export interface UpdateLandFilePayloadDTO {
  /** File type */
  type: 'image' | 'document' | 'covers'
}

export interface AddEmailAddressDTO {
  /** email address */
  address: string
}

export interface EmailAddressDTO {
  /** Unique identifier */
  id: string
  /** email address */
  address: string
  /**
   * Created at
   * @format date-time
   */
  createdAt: string
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://api.stage.rwa-estate.com'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key)
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title RWA Estate
 * @baseUrl https://api.stage.rwa-estate.com
 * @externalDocs /api/swagger.json
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  constructor() {
    const config = useRuntimeConfig()
    const baseUrl = config.public.baseURL as string

    super({ baseUrl })
  }

  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignOut
     * @summary Logout
     * @request POST:/api/auth/sign-out
     * @secure
     */
    authControllerSignOut: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/sign-out`,
        method: 'POST',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefresh
     * @summary Refresh JWT token
     * @request POST:/api/auth/token/refresh
     * @secure
     */
    authControllerRefresh: (params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/token/refresh`,
        method: 'POST',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUp
     * @summary Sign up
     * @request POST:/api/auth/native/sign-up
     */
    authNativeControllerSignUp: (data: SignUpDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpConfirm
     * @summary Sign up confirm
     * @request POST:/api/auth/native/sign-up/confirm
     */
    authNativeControllerSignUpConfirm: (data: SignUpConfirmDTO, params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/native/sign-up/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpAppend
     * @summary Append to exists account
     * @request POST:/api/auth/native/sign-up/append
     */
    authNativeControllerSignUpAppend: (data: SignUpAppendDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/append`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpAppendConfirm
     * @summary Append to exists account
     * @request POST:/api/auth/native/sign-up/append/confirm
     */
    authNativeControllerSignUpAppendConfirm: (data: SignUpAppendConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/append/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignUpResend
     * @summary Resend sign up
     * @request POST:/api/auth/native/sign-up/resend
     */
    authNativeControllerSignUpResend: (data: SignUpResendDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/sign-up/resend`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerSignIn
     * @summary Sign in
     * @request POST:/api/auth/native/sign-in
     */
    authNativeControllerSignIn: (data: SignInDTO, params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/native/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordRecovery
     * @summary Password recovery
     * @request POST:/api/auth/native/password-recovery
     */
    authNativeControllerPasswordRecovery: (data: PasswordRecoveryDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-recovery`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordRecoveryConfirm
     * @summary Password recovery confirm
     * @request POST:/api/auth/native/password-recovery/confirm
     */
    authNativeControllerPasswordRecoveryConfirm: (data: PasswordRecoveryConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-recovery/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordChange
     * @summary Password change
     * @request POST:/api/auth/native/password-change
     * @secure
     */
    authNativeControllerPasswordChange: (data: PasswordChangeDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-change`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthNativeControllerPasswordChangeConfirm
     * @summary Password change confirm
     * @request POST:/api/auth/native/password-change/confirm
     * @secure
     */
    authNativeControllerPasswordChangeConfirm: (data: PasswordChangeConfirmDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/native/password-change/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * @description Accepts query parameters: authCode, scope and grantType for authorization in Google OAuth2.0. If parameters are not passed, redirects to the Google OAuth2.0 authorization form. Allows authorization in two ways: 1. Full authorization on the BackEnd, steps: - Front redirects to the Google authorization address on the backend - Back redirects to Google - The user logs in to Google and is redirected to the backend - Backend returns jwt tokens 2. Partial authorization FrontEnd + BackEnd, steps: - FrontEnd performs a redirect to Google OAuth2.0 - The user, having logged into Google, is redirected to FrontEnd - FrontEnd redirects the received query parameters to BackEnd - Backend returns jwt tokens
     *
     * @tags Auth
     * @name AuthGoogleControllerAuth
     * @summary Google Auth
     * @request GET:/api/auth/google
     */
    authGoogleControllerAuth: (params: RequestParams = {}) =>
      this.request<JwtResponseDTO, any>({
        path: `/api/auth/google`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetMe
     * @summary Requests profile
     * @request GET:/api/users/me
     * @secure
     */
    usersControllerGetMe: (params: RequestParams = {}) =>
      this.request<UserDTO, any>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdate
     * @summary Update profile
     * @request PUT:/api/users/me
     * @secure
     */
    usersControllerUpdate: (data: UpdateUserDTO, params: RequestParams = {}) =>
      this.request<UserDTO, any>({
        path: `/api/users/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerList
     * @summary Get providers.
     * @request GET:/api/providers
     * @secure
     */
    providersControllerList: (params: RequestParams = {}) =>
      this.request<ProviderDTO[], UnauthorizedExceptionDTO>({
        path: `/api/providers`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerListReferralLinks
     * @summary Get provider referral links.
     * @request GET:/api/providers/{name}/referral-links
     * @secure
     */
    providersControllerListReferralLinks: (name: string, params: RequestParams = {}) =>
      this.request<ProviderReferralLinkDTO[], UnauthorizedExceptionDTO | void>({
        path: `/api/providers/${name}/referral-links`,
        method: 'GET',
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerCreateReferralLink
     * @summary Create provider referral link.
     * @request POST:/api/providers/{name}/referral-links
     * @secure
     */
    providersControllerCreateReferralLink: (
      name: string,
      data: CreateProviderReferralLinkDTO,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerUpdateReferralLink
     * @summary Update provider referral link.
     * @request PATCH:/api/providers/{name}/referral-links/{id}
     * @secure
     */
    providersControllerUpdateReferralLink: (
      name: string,
      id: string,
      data: UpdateProviderReferralLinkDTO,
      params: RequestParams = {}
    ) =>
      this.request<ProviderReferralLinkDTO, BadRequestExceptionDTO | UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Providers
     * @name ProvidersControllerDeleteReferralLink
     * @summary Delete provider referral link.
     * @request DELETE:/api/providers/{name}/referral-links/{id}
     * @secure
     */
    providersControllerDeleteReferralLink: (name: string, id: string, params: RequestParams = {}) =>
      this.request<void, UnauthorizedExceptionDTO | NotFoundExceptionDTO>({
        path: `/api/providers/${name}/referral-links/${id}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Chains
     * @name ChainsControllerList
     * @summary List all chains
     * @request GET:/api/chains
     */
    chainsControllerList: (params: RequestParams = {}) =>
      this.request<ChainDTO[], any>({
        path: `/api/chains`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesControllerList
     * @summary List all countries
     * @request GET:/api/countries
     */
    countriesControllerList: (params: RequestParams = {}) =>
      this.request<CountryDTO[], any>({
        path: `/api/countries`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Currency rates
     * @name CurrencyRatesControllerList
     * @summary Use this endpoint to list currency rates
     * @request GET:/api/currency-rates
     */
    currencyRatesControllerList: (
      query?: {
        /** List of currency */
        currency?: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/currency-rates`,
        method: 'GET',
        query: query,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadFile
     * @summary Use this endpoint to upload file
     * @request POST:/api/files
     * @secure
     */
    filesControllerUploadFile: (
      data: {
        /** @format binary */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<FileDTO, void>({
        path: `/api/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerImport
     * @summary Use this endpoint to import file from url
     * @request PUT:/api/files
     * @secure
     */
    filesControllerImport: (data: ImportFileDTO, params: RequestParams = {}) =>
      this.request<FileDTO[], any>({
        path: `/api/files`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerList
     * @summary Use this endpoint to list token
     * @request GET:/api/tokens
     */
    tokensControllerList: (params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/tokens`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerCreate
     * @summary Use this endpoint to create token
     * @request POST:/api/tokens
     * @secure
     */
    tokensControllerCreate: (data: CreateTokenPayloadDTO, params: RequestParams = {}) =>
      this.request<TokenDTO, any>({
        path: `/api/tokens`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerRetrieve
     * @summary Use this endpoint to retrieve token
     * @request GET:/api/tokens/{tokenId}
     */
    tokensControllerRetrieve: (tokenId: string, params: RequestParams = {}) =>
      this.request<TokenDTO, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerUpdate
     * @summary Use this endpoint to update token
     * @request PATCH:/api/tokens/{tokenId}
     * @secure
     */
    tokensControllerUpdate: (tokenId: string, data: UpdateTokenPayloadDTO, params: RequestParams = {}) =>
      this.request<TokenDTO, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Tokens
     * @name TokensControllerDelete
     * @summary Use this endpoint to delete token
     * @request DELETE:/api/tokens/{tokenId}
     * @secure
     */
    tokensControllerDelete: (tokenId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tokens/${tokenId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerListPost
     * @summary Use this endpoint to list estate
     * @request POST:/api/estates/filter
     */
    estatesControllerListPost: (data: EstateFilterDTO, params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/estates/filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerCreate
     * @summary Use this endpoint to create estate
     * @request POST:/api/estates
     * @secure
     */
    estatesControllerCreate: (data: CreateEstatePayloadDTO, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerSetPriority
     * @summary Use this endpoint to setup priority of estate
     * @request PATCH:/api/estates/priority
     * @secure
     */
    estatesControllerSetPriority: (data: UpdateEstatePriorityDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/priority`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetFilterStatsPost
     * @summary Use this endpoint to retrieve estate count by filter
     * @request POST:/api/estates/stats/filters
     */
    estatesControllerGetFilterStatsPost: (data: EstateFilterDTO, params: RequestParams = {}) =>
      this.request<EstateStatisticFilterResponseDTO, any>({
        path: `/api/estates/stats/filters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerGetFiles
     * @summary Use this endpoint to retrieve all estate files
     * @request GET:/api/estates/files
     * @secure
     */
    estatesControllerGetFiles: (
      query: {
        /** Unique identifier */
        type: 'image' | 'document' | 'covers'
      },
      params: RequestParams = {}
    ) =>
      this.request<EstateFileDTO, any>({
        path: `/api/estates/files`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateReferralLink
     * @summary Use this endpoint to update estate referral link
     * @request PATCH:/api/estates/referralLink
     */
    estatesControllerUpdateReferralLink: (data: UpdateEstateReferralLinkDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/referralLink`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerRetrieve
     * @summary Use this endpoint to retrieve estate
     * @request GET:/api/estates/{estateId}
     */
    estatesControllerRetrieve: (estateId: string, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateMetrics
     * @summary Use this endpoint to store metrics
     * @request PUT:/api/estates/{estateId}
     */
    estatesControllerUpdateMetrics: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdate
     * @summary Use this endpoint to update estate
     * @request PATCH:/api/estates/{estateId}
     * @secure
     */
    estatesControllerUpdate: (estateId: string, data: UpdateEstatePayloadDTO, params: RequestParams = {}) =>
      this.request<EstateResponseDTO, any>({
        path: `/api/estates/${estateId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerDelete
     * @summary Use this endpoint to delete estate
     * @request DELETE:/api/estates/{estateId}
     * @secure
     */
    estatesControllerDelete: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateStatus
     * @summary Use this endpoint to update estate status
     * @request PATCH:/api/estates/{estateId}/status/{status}
     * @secure
     */
    estatesControllerUpdateStatus: (
      estateId: string,
      status: 'new' | 'draft' | 'moderating' | 'preview' | 'published' | 'sold_out' | 'deleted',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/status/${status}`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerUpdateFileType
     * @summary Use this endpoint to update type of estate file
     * @request PATCH:/api/estates/{estateId}/file/{fileId}
     * @secure
     */
    estatesControllerUpdateFileType: (
      estateId: string,
      fileId: string,
      data: UpdateEstateFilePayloadDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * @description Setup estate timeline stage date. If stage already exists, it will be updated
     *
     * @tags Estates
     * @name EstatesControllerUpdateTimeline
     * @summary Use this endpoint to setup timeline of estate
     * @request PUT:/api/estates/{estateId}/timeline
     * @secure
     */
    estatesControllerUpdateTimeline: (estateId: string, data: CreateEstateTimelineDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/timeline`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates
     * @name EstatesControllerDeleteTimeline
     * @summary Use this endpoint to delete estate timeline
     * @request DELETE:/api/estates/{estateId}/timeline/{stage}
     * @secure
     */
    estatesControllerDeleteTimeline: (
      estateId: string,
      stage?:
        | 'in_progress'
        | 'complete'
        | 'ConstructionLaunched'
        | 'ConstructionFinished'
        | 'ListingDate'
        | 'PropertyAvaliableForRental'
        | 'StartOfFirstPayments',
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/timeline/${stage}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Estates, Users
     * @name EstatesControllerFavorite
     * @summary Use this endpoint to favorite estate
     * @request PATCH:/api/estates/{estateId}/favorite
     * @secure
     */
    estatesControllerFavorite: (estateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/estates/${estateId}/favorite`,
        method: 'PATCH',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerListPost
     * @summary Use this endpoint to list land
     * @request POST:/api/lands/filter
     */
    landsControllerListPost: (data: LandFilterDTO, params: RequestParams = {}) =>
      this.request<PaginationResultDto, any>({
        path: `/api/lands/filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerCreate
     * @summary Use this endpoint to create land
     * @request POST:/api/lands
     * @secure
     */
    landsControllerCreate: (data: CreateLandDTO, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerRetrieve
     * @summary Use this endpoint to retrieve land
     * @request GET:/api/lands/{landId}
     */
    landsControllerRetrieve: (landId: string, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}`,
        method: 'GET',
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdateMetrics
     * @summary Use this endpoint to store metrics
     * @request PUT:/api/lands/{landId}
     */
    landsControllerUpdateMetrics: (landId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/lands/${landId}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdate
     * @summary Use this endpoint to update land
     * @request PATCH:/api/lands/{landId}
     * @secure
     */
    landsControllerUpdate: (landId: string, data: UpdateLandDTO, params: RequestParams = {}) =>
      this.request<LandResponseDTO, any>({
        path: `/api/lands/${landId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerDelete
     * @summary Use this endpoint to delete land
     * @request DELETE:/api/lands/{landId}
     * @secure
     */
    landsControllerDelete: (landId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/lands/${landId}`,
        method: 'DELETE',
        secure: true,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Lands
     * @name LandsControllerUpdateFileType
     * @summary Use this endpoint to update type of land file
     * @request PATCH:/api/lands/{landId}/file/{fileId}
     * @secure
     */
    landsControllerUpdateFileType: (
      landId: string,
      fileId: string,
      data: UpdateLandFilePayloadDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/lands/${landId}/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      }),

    /**
     * No description
     *
     * @tags Mailing list
     * @name MailingListControllerRetrieve
     * @summary Use this endpoint to add mail to mailing list
     * @request POST:/api/mailing-list/email
     */
    mailingListControllerRetrieve: (data: AddEmailAddressDTO, params: RequestParams = {}) =>
      this.request<EmailAddressDTO, any>({
        path: `/api/mailing-list/email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        headers: {
          Authorization: `Bearer ${useCookie('acc_token').value}`
        },
        ...params
      })
  }
}
